const contractAddresses = require("./contractAddresses.json");
const abi = require("./abi.json");
const BUSDabi = require("./BUSDabi.json");
const abiDUES = require("./abiDUES.json");
//const USDCabi = require("./USDCabi.json");
//const USDTabi = require("./USDTabi.json");
//const DAICabi = require("./DAIabi.json");
//const BNBCabi = require("./BNBabi.json");
module.exports = {
  abi,
  contractAddresses,
  BUSDabi,
  abiDUES,
  //USDCabi,
  //USDTabi,
  //BNBCabi,
  //DAICabi,
};
